import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

// components
import Form from "./Form";
// entity
import { Bonus, DropDownOption } from "../../../../models/domain";
// services
import * as userActionCreators from "../../../../actions/app.actions";
import * as settingsService from "../../../../services/api/hr/settings.service";
import * as employeesService from "../../../../services/api/hr/employees.service";
import { addBonusFormValidation } from "../../../../services/validations/hr/bonusFormValidation";
import { handleAddBonusError } from "../../../../services/errors/hr//AddBonusError";
import { override } from "../../../../services/loadingOverride.styles";
// styles
import AddBonusStyles from "./AddBonusStyles";
import { useTranslation } from "react-i18next";

const AddBonus = ({
  closeModal,
  modalType,
  companyId,
  employees,
  employeeId,
  user,
  finishOnboarding
}) => {
  const { t } = useTranslation();
  const [tokenWorth, setTokenWorth] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isNotCompanyBonusBudget, setIsNotCompanyBonusBudget] = useState(false);
  const selectOptions = DropDownOption.createOptions(employees);
  const isMounted = useRef(false);

  const fetchCompanyBonusBudgetData = async () => {
    const response = await employeesService.getCompanyBonusBudget();

    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : "Something went wrong"
      );
    }

    if (!response?.data?.id && isMounted.current) {
      setIsNotCompanyBonusBudget(true);
    }
  };

  const fetchToken = async () => {
    const responseTokenWorth = await settingsService.getTokenWorth();

    if (isMounted.current) {
      setTokenWorth(responseTokenWorth);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    fetchToken();
    fetchCompanyBonusBudgetData();

    return () => {
      fetchToken();
      fetchCompanyBonusBudgetData();
      isMounted.current = false;
    };
  }, []);

  const onboardingDone = async () => {
    if (user.serverOnboarding === false) {
      const response = await settingsService.onboardingFinish();

      if (response.hasError) {
        return toast.error(response.error.data.error.message);
      }

      finishOnboarding();
    }
  };

  const createBonusSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const response = await employeesService.addBonus(values);

    if (response.hasError) {
      setSubmitting(false);
      return handleAddBonusError(response);
    }

    toast.success(t("success_add_bonus"));
    setSubmitting(false);
    closeModal();
    onboardingDone();
  };

  const formik = useFormik({
    initialValues: new Bonus({
      employees: employeeId
        ? selectOptions.filter(option => option.id === employeeId)
        : [],
      companyId: companyId,
      endDate: "",
      startDate: "",
      recurring: false,
      recurringType: ""
    }),
    validate: addBonusFormValidation,
    onSubmit: createBonusSubmit
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    handleChange,
    handleBlur
  } = formik;

  return isLoading ? (
    <div
      style={{
        height: "calc(100vh - 180px)",
        width: "545px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <BeatLoader
        size={25}
        color="#123abc"
        loading={isLoading}
        css={override}
      />
    </div>
  ) : (
    <AddBonusStyles>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Form
          title={t(modalType.title)}
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          tokenWorth={tokenWorth}
          disableButton={isSubmitting}
          buttonText="Create"
          inputName="subject"
          textareaName="message"
          selectName={modalType.selectName}
          selectOptions={selectOptions}
          isNotCompanyBonusBudget={isNotCompanyBonusBudget}
        />
      </form>
    </AddBonusStyles>
  );
};

AddBonus.propTypes = {
  addBonus: PropTypes.func,
  closeModal: PropTypes.func,
  modalType: PropTypes.shape({
    title: PropTypes.string,
    selectName: PropTypes.string
  }),
  companyId: PropTypes.number,
  employees: PropTypes.arrayOf(PropTypes.shape({})),
  employeeId: PropTypes.number,
  user: PropTypes.shape({}),
  finishOnboarding: PropTypes.func
};

const mapStateToProps = state => {
  return {
    user: state.app.user,
    companyId: state.app.user.companyId,
    employees: state.employees.employees
  };
};

const mapDispatchToProps = dispatch => {
  return {
    finishOnboarding: () => dispatch(userActionCreators.finishOnboarding())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBonus);
