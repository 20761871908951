import React from "react";
import PropTypes from "prop-types";
// Styles
import PriceFilterStyles from "./PriceFilter.styles";
// Assets
import { TokenIcon } from "../../../../../assets/Icons";
// Constants
import {
  PRICE_MAX,
  PRICE_MIN,
  PRICE_STEP
} from "../../../../../constants/filter.constants";
// Component
import { TwoThumbs } from "../../../../../components/range";

const PriceFilter = ({ handlePriceChange, priceRange }) => {
  return (
    <PriceFilterStyles>
      <div className="inputWrapper">
        <div className="inputContainer">
          <label>
            <TokenIcon />
          </label>
          <input
            type="number"
            min={PRICE_MIN}
            max={priceRange[1]}
            name="minPrice"
            step={PRICE_STEP}
            value={priceRange[0]}
            onChange={e =>
              handlePriceChange([e.target.value * 1, priceRange[1]])
            }
          />
        </div>
        <span>-</span>
        <div className="inputContainer">
          <label>
            <TokenIcon />
          </label>
          <input
            type="number"
            min={priceRange[0]}
            max={PRICE_MAX}
            name="maxPrice"
            step={PRICE_STEP}
            value={priceRange[1]}
            onChange={e =>
              handlePriceChange([priceRange[0], e.target.value * 1])
            }
          />
          {priceRange[1] === 1000 && <span>+</span>}
        </div>
      </div>
      <TwoThumbs
        values={priceRange}
        onRangeChange={handlePriceChange}
        rtl={false}
      />
    </PriceFilterStyles>
  );
};

PriceFilter.propTypes = {
  handleRangeChange: PropTypes.func,
  priceRange: PropTypes.array
};

export default PriceFilter;
