import { isEmpty, removeSpacesAndHTML } from "../general.utils";
import { convertEditorStateToString } from "../textEditor.utils";

export const validateBenefitForm = ({
  title,
  // photo,
  expiration,
  tokenPrice,
  description,
  vouchers,
  summary,
  quantity,
  instalments,
  usageInstructions
}) => {
  const isInLanguage = findLocales("rs");

  const errors = {};

  if (!title.trim()) {
    errors.title = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (title.length > 30) {
    errors.title = "Name too long";
  } else if (!removeSpacesAndHTML(title)) {
    errors.title = "HTML tag is not allowed";
  }

  const descriptionString = convertEditorStateToString(description);
  const tagRegex = /<.+?>/g;
  const formattedDescription = descriptionString.replace(tagRegex, "").trim();
  if (formattedDescription.length > 1001) {
    errors.description = "Too long";
  }

  if (!summary.trim()) {
    errors.summary = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (removeSpacesAndHTML(summary).length < 20) {
    errors.summary = "Too short";
  } else if (removeSpacesAndHTML(summary).length > 90) {
    errors.summary = "Too long";
  }

  if (!isEmpty(usageInstructions)) {
    const usageInstructionsString = convertEditorStateToString(
      usageInstructions
    );
    const usageTagRegex = /<.+?>/g;
    const formattedUsageInstructions = usageInstructionsString
      .replace(usageTagRegex, "")
      .trim();
    if (
      !isEmpty(formattedUsageInstructions) &&
      formattedUsageInstructions.length > 1001
    ) {
      errors.usageInstructions = "Too long";
    }
  }

  if (expiration === "") {
    errors.expiration = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  if (expiration !== "voucher") {
    if (tokenPrice === "" || tokenPrice === null || tokenPrice === undefined) {
      errors.tokenPrice = isInLanguage ? "*Obavezno polje" : "* Required";
    }
  }

  if (expiration === "voucher") {
    if (vouchers.length === 0) {
      errors.vouchers = "Please add voucher";
    } else {
      vouchers.forEach(voucher => {
        if (voucher.name === "" || voucher.price === "") {
          errors.vouchers = "You need to fill all the fields";
        }
      });
    }
  }

  if (!isEmpty(tokenPrice) && tokenPrice <= 0) {
    errors.tokenPrice = "Minimum value for token price is 1";
  }

  if (
    expiration === "one time" &&
    !isEmpty(instalments) &&
    tokenPrice % instalments !== 0
  ) {
    errors.tokenPrice =
      "Token price should be divisible by the number of instalments";
  }

  if (!isEmpty(quantity) && quantity <= 0)
    errors.quantity = "Minimum value for quantity is 1";

  return errors;
};
