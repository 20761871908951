import React, { useState } from "react";
import { TokenIcon } from "../../../../assets/Icons";
import { MultiselectInput } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { setAdminFilters } from "../../../../actions/admin/admin.actions";
import {
  transformTokenCurrencyIcons,
  transformTokensToRsd
} from "../../../../utils/transformTokensToRsd";

const listOfOptions = [
  { id: 1, name: "Budget" },
  { id: 2, name: "Subscription" }
];

export const AdminUserHistoryHeader = ({ tokens }) => {
  const dispatch = useDispatch();

  const { filters } = useSelector(state => state.admin);

  const handleSetHistoryFilter = options => {
    dispatch(setAdminFilters({ ...filters, page: 1, type: options || [] }));
  };

  const isInRSD = localStorage.getItem("isValueInRsd");

  return (
    <div className="adminUserHistoryHeader">
      <div className="adminUserHistoryHeaderTokens">
        <p className="adminUserHistoryHeaderTokenItem">
          Total {isInRSD ? "RSD" : "Tokens"}:{" "}
          <b>{transformTokensToRsd(tokens.totalTokens)}</b>{" "}
          {transformTokenCurrencyIcons()}
        </p>
        <p className="adminUserHistoryHeaderTokenItem">
          Remaining {isInRSD ? "RSD" : "Tokens"}:{" "}
          <b>{transformTokensToRsd(tokens.remainingTokens)}</b>{" "}
          {transformTokenCurrencyIcons()}
        </p>
      </div>
      <div className="adminUserHistoryHeaderFilter">
        <MultiselectInput
          name="history"
          options={listOfOptions}
          shouldHaveFullHeight={true}
          placeholder="Filter"
          handleChange={handleSetHistoryFilter}
          selectedValues={filters.type}
        />
      </div>
    </div>
  );
};
