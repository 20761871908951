import React, { useEffect } from "react";
import DashboardStatisticsStyles from "./DashboardStatisticsStyles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { apiService } from "../../../../services/api/api.service";
import { queryBuilder } from "../../../../utils/queryBuilder";
import adminDashboardStatisticAlgorithm from "../../../../utils/helpers/adminDashboardStatisticAlgorithm";
import ReactApexChart from "react-apexcharts";
import {
  setAdminDashboardStatistics,
  setAdminDashboardUserBenefits
} from "../../../../actions/admin/dashboard.actions";
import { handleChartOptions } from "./helpers/handleChartOptions";
import { DashboardStatisticsNavbar } from "./DashboardStatisticsNavbar/DashboardStatisticsNavbar";
import { useTranslation } from "react-i18next";

export const DashboardStatistics = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filters, statistics } = useSelector(state => state.adminDashboard);

  const fetchDashboardStatistics = async payload => {
    try {
      const query = queryBuilder({
        baseQuery: "/dashboard/admin-statistics",
        queryObject: payload
      });

      const response = await apiService.get(query);

      const uniqueUsers = Array.from(
        new Map(response.data.items.map(item => [item.userId, item])).values()
      );

      const data = adminDashboardStatisticAlgorithm(uniqueUsers);

      dispatch(setAdminDashboardUserBenefits(uniqueUsers));
      dispatch(setAdminDashboardStatistics(data));
    } catch (error) {
      toast.error(t("failed_to_get_admin_statistics"));
    }
  };

  useEffect(() => {
    fetchDashboardStatistics(filters);
  }, [filters]);

  return (
    <DashboardStatisticsStyles>
      {statistics && (
        <>
          <DashboardStatisticsNavbar />
          <ReactApexChart
            options={{
              xaxis: { categories: statistics.dates }
            }}
            series={handleChartOptions(statistics.statistic)}
            type="bar"
            height={285}
          />
        </>
      )}
    </DashboardStatisticsStyles>
  );
};
