import validator from "validator";
import moment from "moment";

// Utils
import { isEmpty, removeSpacesAndHTML } from "../general.utils";
import { doesRoleExist } from "../roles.utils";

// Constants
import { UserRolesKeysEnum } from "../../constants/roles.constants";
import { findLocales } from "../../utils/helpers/findLocales";

const allowedDateFormats = [
  "DD/MM/YYYY",
  "D/M/YYYY",
  "DD.MM.YYYY",
  "D.M.YYYY",
  "DD-MM-YYYY",
  "D-M-YYYY"
];

const validateDate = value => moment(value, allowedDateFormats, true).isValid();

export const addUserFormValidation = ({
  firstName,
  lastName,
  email,
  bornDate,
  firstDayAtWork,
  roles,
  tier,
  companyId,
  cities
}) => {
  const errors = {};
  const isInLanguage = findLocales("rs");

  if (!roles) errors.roles = isInLanguage ? "*Obavezno polje" : "* Required";

  if (!firstName || !/\S/.test(firstName))
    errors.firstName = isInLanguage ? "*Obavezno polje" : "* Required";
  else if (!removeSpacesAndHTML(firstName)) {
    errors.firstName = "HTML tag is not allowed";
  }

  if (!lastName || !/\S/.test(lastName))
    errors.lastName = isInLanguage ? "*Obavezno polje" : "* Required";
  else if (!removeSpacesAndHTML(lastName)) {
    errors.lastName = "HTML tag is not allowed";
  }

  if (!email) errors.email = isInLanguage ? "*Obavezno polje" : "* Required";
  else if (!validator.isEmail(email)) errors.email = "Invalid email";

  if (
    doesRoleExist(roles, UserRolesKeysEnum.MEMBER) ||
    doesRoleExist(roles, UserRolesKeysEnum.HR)
  ) {
    if (!companyId)
      errors.companyId = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  if (doesRoleExist(roles, UserRolesKeysEnum.MEMBER) && isEmpty(cities)) {
    errors.cities = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  if (doesRoleExist(roles, UserRolesKeysEnum.MEMBER)) {
    if (isEmpty(cities)) {
      errors.cities = isInLanguage ? "*Obavezno polje" : "* Required";
    }

    if (typeof tier === Object) {
      if (tier.id === null) {
        errors.tier = isInLanguage ? "*Obavezno polje" : "* Required";
      }
    } else {
      if (!tier) {
        errors.tier = isInLanguage ? "*Obavezno polje" : "* Required";
      }
    }
  }

  return errors;
};
