import validator from "validator";
import { findLocales } from "../../../utils/helpers/findLocales";

export const resetPasswordValidation = ({
  email,
  password,
  passwordConfirmation
}) => {
  const isInLanguage = findLocales("rs");
  const errors = {};
  if (!email) {
    errors.email = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (!validator.isEmail(email)) {
    errors.email = "Invalid email";
  }

  if (!password) {
    errors.password = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (password.length < 8) {
    errors.password = "At least 8 characters";
  } else if (!password.match(/\d+/g)) {
    errors.password = "Must contain number";
  } else if (!password.match(/[a-zA-Z]/)) {
    errors.password = "Must contain letters";
  }

  if (!passwordConfirmation) {
    errors.passwordConfirmation = isInLanguage
      ? "*Obavezno polje"
      : "* Required";
  } else if (passwordConfirmation !== password) {
    errors.passwordConfirmation = "No match";
  }

  return errors;
};
