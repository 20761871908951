import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
// components
import { Card } from "../../../../components";
// styles
import BudgetUtilizationStyles from "./BudgetUtilizationStyles";
// config
import { budgetUtilizationConfig } from "./budgetUtilization.config";
// services
import { formatDateForSearch } from "../dashboad.services";
import * as dashboardService from "../../../../services/api/hr/dashboard.service";
import { useTranslation } from "react-i18next";

const BudgetUtilization = ({
  budgetUtilizationLoading,
  setBudgetUtilizationLoading
}) => {
  const { t } = useTranslation();
  const [budgetUtilization, setBudgetUtilization] = useState(
    budgetUtilizationConfig
  );
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [date, setDate] = useState(new Date());
  const [searchDateString, setSearchDateString] = useState(
    formatDateForSearch(new Date())
  );
  const isMounted = useRef(false);

  const handleDateChange = chosenDate => {
    setDate(chosenDate);
    setSearchDateString(formatDateForSearch(chosenDate));
  };

  const fetchData = async () => {
    const response = await dashboardService.fetchBudgetSpending(
      searchDateString
    );

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_budget_spending")
      );
    }

    if (isMounted.current) {
      setBudgetUtilizationLoading(false);
      if (
        errorMessage !== undefined &&
        (response.lastSpentBudget !== 0 || response.actualSpentBudget !== 0)
      ) {
        setErrorMessage(undefined);
      } else if (
        response.actualSpentBudget === 0 &&
        response.lastSpentBudget === 0
      ) {
        setErrorMessage("No data to compare");
      }
      if (response && isMounted.current) {
        setBudgetUtilization({
          ...budgetUtilization,
          series: [response.actualSpentBudget, response.lastSpentBudget]
        });
      }
    }
  };
  useEffect(() => {
    isMounted.current = true;
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [date]);

  useEffect(() => {
    if (budgetUtilizationLoading === false) {
      const datePicker = document.getElementById("datePickerBudgetSpending");
      if (datePicker) {
        datePicker.setAttribute("readOnly", true);
      }
    }
  }, [budgetUtilizationLoading]);

  return (
    <BudgetUtilizationStyles>
      <Card
        boxShadow="
					0 2px 4px -1px rgba(0, 0, 0, 0.2),
					0 1px 10px 0 rgba(0, 0, 0, 0.12),
					0 4px 5px 0 rgba(0, 0, 0, 0.14)
				"
      >
        <div className="titleContainer">
          <DatePicker
            className="datePickerInput"
            id="datePickerBudgetSpending"
            selected={date}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            onChange={chosenDate => handleDateChange(chosenDate)}
            value={date}
            maxDate={new Date()}
          />
          <h3 className="title">{t("budget_spent")}</h3>
        </div>
        <div className="chartAndMessageContainer">
          {errorMessage && (
            <div className="messageContainer">
              <p className="text">{errorMessage}</p>
            </div>
          )}
          <ReactApexChart
            options={budgetUtilization.options}
            series={budgetUtilization.series}
            type="donut"
            width={250}
            height={250}
          />
          <div className="legend">
            <div className="item">
              <div className="dot" style={{ background: "#0A243F" }} />
              <p className="description">
                {t("chosen_month")} - {budgetUtilization.series[0]} RSD
              </p>
            </div>
            <div className="item">
              <div className="dot" style={{ background: "#1B6B70" }} />
              <p className="description">
                {t("previous_month")} - {budgetUtilization.series[1]} RSD
              </p>
            </div>
          </div>
        </div>
      </Card>
    </BudgetUtilizationStyles>
  );
};

BudgetUtilization.propTypes = {
  budgetUtilizationLoading: PropTypes.bool,
  setBudgetUtilizationLoading: PropTypes.func
};

export default BudgetUtilization;
