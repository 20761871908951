import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

// Components
import MultiselectInputStyles from "./MultiselectInputStyles";
import itemRenderer from "../selectInput/itemRendered";
import { useTranslation } from "react-i18next";

const MultiselectInput = ({
  value,
  name,
  options,
  selectedValues,
  shouldHaveHeight = false,
  shouldHaveFullWidth = false,
  containerShouldHaveFullWidth = false,
  shouldHaveFullHeigth = false,
  handleChange,
  isDisabled = false,
  placeholder = "Select items",
  fetchMoreData = () => {},
  handleInputChange = () => {},
  customHeight = "100%",
  customInputFieldHeight = "100%"
}) => {
  const { t } = useTranslation();

  /**
   * Handles on scroll event in multiselect dropdown.
   * Fires when list is scrolled to the end.
   * Fetches more data for the list.
   * Request is debounced.
   */
  const handleOnScroll = () => {
    setTimeout(() => {
      fetchMoreData();
    }, 300);
  };

  /**
   * Handles input change.
   * Request is debounced.
   * @param {string} searchText
   */
  const debounceInputChange = searchText => {
    setTimeout(() => {
      handleInputChange(searchText);
    }, 500);
  };

  return (
    <MultiselectInputStyles
      shouldHaveHeight={shouldHaveHeight}
      shouldHaveFullWidth={shouldHaveFullWidth}
      containerShouldHaveFullWidth={containerShouldHaveFullWidth}
      shouldHaveFullHeigth={shouldHaveFullHeigth}
      customHeight={customHeight}
      customInputFieldHeight={customInputFieldHeight}
    >
      <Select
        value={selectedValues}
        className="react-dropdown-select"
        classNamePrefix="react-dropdown"
        placeholder={placeholder}
        labelField="name"
        valueField="id"
        getOptionLabel={options => options["name"]}
        getOptionValue={options => options["id"]}
        isMulti
        isDisabled={isDisabled}
        name={name}
        options={options}
        dropdownPosition="auto"
        onMenuScrollToBottom={handleOnScroll}
        onInputChange={debounceInputChange}
        onChange={(values, action) => handleChange(values, action)}
        itemRenderer={
          itemRenderer
            ? (item, itemIndex, props, state, methods) =>
                itemRenderer(item, itemIndex, props, state, methods)
            : undefined
        }
      />
    </MultiselectInputStyles>
  );
};

MultiselectInput.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  selectedValues: PropTypes.array,
  shouldHaveHight: PropTypes.bool,
  shouldHaveFullWidth: PropTypes.bool,
  handleChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  fetchMoreData: PropTypes.func
};

export default MultiselectInput;
