import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
// assets
import { EmployeesIcon, LogoutIcon } from "../../assets/Icons";
//components
import { CompanyThemeLogo } from "../../components";
//styles
import SidebarMenuStyles from "./SidebarMenuStyles";
import SidebarMenuTab from "./sidebarMenuTab";
//constants
import { BENEFIT_ROLE } from "../../constants/localStorage.constants";
//config
import { navigationConfig, navLogos } from "../../config/navigation.config";
//actions
import * as actionCreators from "../../actions/app.actions";
import * as actionHRCreators from "../../actions/hr/employees.actions";
//services
import { filterArrayByValueOfKey, isEmpty } from "../../services/general.utils";
//hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { apiService } from "../../services/api/api.service";
import { useTranslation } from "react-i18next";
import { LanguageDropdown } from "../locales/LanguageDropdown";

const SidebarMenu = ({
  logout,
  user,
  isSidebarMenuOpen,
  toggleSidebarMenu
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  const [listOfLinks, setLitOfLinks] = useState([]);

  const pathForEmployeeAndRefund =
    pathname.match("employees") || pathname.match("refunds");

  const isShowSidebar =
    isEmpty(pathForEmployeeAndRefund) ||
    (!isEmpty(pathForEmployeeAndRefund) && (isSidebarMenuOpen || width >= 767));

  const refundPage = {
    path: "/refunds",
    title: "Refunds"
  };

  const getListOfCompaniesThatHaveRefunds = async () => {
    if (!user?.authenticatedRole?.key) return;
    if (user?.authenticatedRole?.key === "member") return;

    try {
      const response = await apiService.get(
        "/company/get-all-refund-companies"
      );
      const companyIds = response.data.items.map(company => company.id);

      const links = JSON.parse(
        JSON.stringify(navigationConfig[user?.authenticatedRole?.key])
      );

      const shouldShowRefundPage =
        user?.authenticatedRole?.key === "hr" &&
        companyIds.includes(user.companyId) &&
        !links.includes(refundPage);

      if (shouldShowRefundPage) links.push(refundPage);

      setLitOfLinks(links);
    } catch (error) {
      console.log("Unable to get companies that have refunds", error);
    }
  };

  useEffect(() => {
    getListOfCompaniesThatHaveRefunds();
  }, [user?.authenticatedRole?.key, location]);

  return (
    /**
     * SidebarMenu is closed by default when screen size < 767px in the HR/Employees page
     */
    isShowSidebar && (
      <SidebarMenuStyles>
        <div className="darkOverlay" />
        <div className="logoContainer">
          {user?.authenticatedRole && (
            <>
              {!isEmpty(pathForEmployeeAndRefund) && width < 767 && (
                <button
                  className="closeSidebarMenuButton"
                  onClick={() => toggleSidebarMenu(isSidebarMenuOpen)}
                >
                  {" "}
                  X{" "}
                </button>
              )}
              <div
                onClick={() => history.push("/")}
                style={{ cursor: "pointer" }}
              >
                <CompanyThemeLogo
                  color="#fff"
                  theme={
                    filterArrayByValueOfKey(user.themes, "mode", user.mode)[0]
                  }
                  colorTheme="#fff"
                />
              </div>
            </>
          )}
        </div>
        {user && user?.authenticatedRole && (
          <div className="sidebarTabsContainer">
            {listOfLinks.map(link => {
              return (
                <NavLink
                  exact
                  to={link.path}
                  key={link.path}
                  style={{ textDecoration: "none" }}
                  className="navLink"
                  activeClassName="isActive"
                >
                  <SidebarMenuTab
                    linkName={t(link.title)}
                    Icon={navLogos[link.title]}
                    paraIconLeftMargin="14.1px"
                  />
                </NavLink>
              );
            })}
            <div className="lngDropdown">
              <LanguageDropdown />
            </div>
            <SidebarMenuTab
              linkName={t("Logout")}
              Icon={LogoutIcon}
              paraIconLeftMargin="18px"
              customCssClass="logoutTab navLink"
              onClick={() => {
                localStorage.removeItem(BENEFIT_ROLE);
                logout();
                isAuthenticated && instance.logoutPopup();
                history.replace("/");
              }}
            />
          </div>
        )}
      </SidebarMenuStyles>
    )
  );
};

SidebarMenu.propTypes = {
  is404: PropTypes.bool,
  isVisible: PropTypes.bool,
  allowedModules: PropTypes.arrayOf(PropTypes.shape()),
  path: PropTypes.string,
  isSidebarMenuOpen: PropTypes.bool,
  toggleSidebarMenu: PropTypes.func,
  logout: PropTypes.func
};

const mapStateToProps = state => {
  return {
    user: state.app.user,
    isSidebarMenuOpen: state.employees.isSidebarMenuOpen
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actionCreators.logout()),
    toggleSidebarMenu: isSidebarOpen =>
      dispatch(actionHRCreators.toggleSidebarMenu(isSidebarOpen))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
