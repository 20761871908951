import validator from "validator";
import moment from "moment";
import { isEmpty, removeSpacesAndHTML } from "../../general.utils";
import { findLocales } from "../../../utils/helpers/findLocales";

const allowedDateFormats = [
  "DD/MM/YYYY",
  "D/M/YYYY",
  "DD.MM.YYYY",
  "D.M.YYYY",
  "DD-MM-YYYY",
  "D-M-YYYY"
];

const validateDate = value => moment(value, allowedDateFormats, true).isValid();

export const hrEmployeeValidations = ({
  firstName,
  lastName,
  email,
  tier,
  cities
}) => {
  const isInLanguage = findLocales("rs");

  const errors = {};

  if (!firstName || !/\S/.test(firstName))
    errors.firstName = isInLanguage ? "*Obavezno polje" : "* Required";
  else if (!removeSpacesAndHTML(firstName)) {
    errors.firstName = "HTML tag is not allowed";
  }

  if (!lastName || !/\S/.test(lastName))
    errors.lastName = isInLanguage ? "*Obavezno polje" : "* Required";
  else if (!removeSpacesAndHTML(lastName)) {
    errors.lastName = "HTML tag is not allowed";
  }

  if (!email) errors.email = isInLanguage ? "*Obavezno polje" : "* Required";
  else if (!validator.isEmail(email)) errors.email = "Invalid email";

  if (!tier) errors.tier = isInLanguage ? "*Obavezno polje" : "* Required";

  if (isEmpty(cities)) {
    errors.cities = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  return errors;
};
