import { findLocales } from "../../../utils/helpers/findLocales";

const SUPPORTED_FORMATS = ["csv"];

export const AddEmployeesWithCSVValidations = ({ csvFile }) => {
  const isInLanguage = findLocales("rs");

  const errors = {};

  if (!csvFile) {
    errors.csvFile = isInLanguage ? "*Obavezno polje" : "* Required";
  }
  if (
    csvFile &&
    csvFile.name &&
    !SUPPORTED_FORMATS.includes(csvFile.name.split(".").pop())
  ) {
    errors.csvFile = "You can upload only .csv file format";
  }
  return errors;
};
