import styled from "styled-components";

const LanguageDropdownStyles = styled.div`
  .flagDropdownWrapper {
    position: relative;
    width: 100px;
  }

  .flagDropdown {
    background: white;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    img {
      width: 15px;
      height: 15px;
    }
  }

  .languageItem {
    cursor: pointer;
    width: 100px;
    display: flex;
    gap: 5px;
    padding: 10px;
    border: none;
    background: transparent;
  }

  .shownFlag {
    border-radius: 4px;
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 12px;
    border: none;
    border-radius: 8px;

    img {
      width: 25px;
      height: 25px;
    }

    .shownLanguage {
      font-size: 12px;
    }
  }
`;

export default LanguageDropdownStyles;
