import React from "react";

import { FlagCurrency, TokenIcon } from "../assets/Icons";
import { apiService } from "../services/api/api.service";

export const checkIfTheValueIsInRsdOrToken = async companyId => {
  if (!companyId) return;

  try {
    const response = await apiService.get(
      `/company/is-company-tokenized/${companyId}`
    );

    const { viewInRsd } = response.data.company;
    return viewInRsd;
  } catch (error) {
    console.error("Unable to get the value for the company.view_in_rsd");
  }
};

export const shouldShowInRsd = async companyId => {
  try {
    const viewInRsd = await checkIfTheValueIsInRsdOrToken(companyId);
    localStorage.setItem("isValueInRsd", viewInRsd);

    return viewInRsd;
  } catch (error) {
    console.error("Unable to set the value for the company.view_in_rsd");
  }
};

export const transformTokensToRsd = valueInTokens => {
  const isValueInRsd = localStorage.getItem("isValueInRsd");

  return isValueInRsd === "true" ? valueInTokens * 20 : valueInTokens;
};

export const transformPriceRangeTokensToPriceRangeRsd = priceRangeInTokens => {
  if (String(priceRangeInTokens) === "0") return 0;

  const priceRange = priceRangeInTokens.split("-");

  if (priceRange.length === 1) return transformTokensToRsd(priceRange);

  const changedPriceRange = priceRange
    .map(item => transformTokensToRsd(item))
    .join(" - ");

  return changedPriceRange;
};

export const transformTokenCurrencyIcons = () => {
  const isValueInRsd = localStorage.getItem("isValueInRsd");

  return isValueInRsd === "true" ? (
    <FlagCurrency />
  ) : (
    <TokenIcon outerFill="#4b4b4b" lineFill="#fff" />
  );
};
