import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Components
import { AddUserFormStyles } from "./AddUserFormStyles";
import {
  Button,
  IntitialsCircle,
  Input,
  InputLabel,
  TextAndLine,
  SelectInput,
  MultiselectInput,
  CheckboxInput
} from "../..";
import { MemberFields } from "./MemberFields";
import { UserDisabledIconSvg } from "../../../assets/Icons";

// Services
import * as api from "../../../services/api/admin/users.service";
import { findUserRole } from "./editUserForm.services";
import { editUserFormValidations } from "../../../services/validations/superAdmin/editUserForm.validations";

// Constants
import { UserRolesKeysEnum } from "../../../constants/roles.constants";

// Utils
import { isEmpty } from "../../../services/general.utils";
import {
  doesRoleExist,
  isAuthenticatedRole
} from "../../../services/roles.utils";
import { useTranslation } from "react-i18next";

const EditUserForm = ({ routeParams }) => {
  const { t } = useTranslation();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companiesPage, setCompaniesPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [roles, setRoles] = useState([]);
  const [chosenUser, setChosenUser] = useState(findUserRole(routeParams.role));
  const history = useHistory();

  const handleUpdateUser = async values => {
    const response = await api.updateUser({
      payload: values,
      id: routeParams.chosenId
    });

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_edit_user"),
        {
          autoClose: 15000
        }
      );
    }

    history.push("/users");
  };

  const resendVerificationLink = async e => {
    e.stopPropagation();
    const userId = isAuthenticatedRole(
      chosenUser.authenticatedRole,
      UserRolesKeysEnum.MEMBER
    )
      ? chosenUser.user.id
      : chosenUser.id;

    const response = await api.resendVerificationLink(userId);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_send_ver_link")
      );
    }

    toast.success(response.data.message);
  };

  /**
   * Handles user enabling.
   * Sends request to update user enabled status.
   * Refetches data.
   * @param {Event} e
   */
  const handleUserEnable = async e => {
    e.stopPropagation();
    const userId = isAuthenticatedRole(
      chosenUser.authenticatedRole,
      UserRolesKeysEnum.MEMBER
    )
      ? chosenUser.user.id
      : chosenUser.id;

    const response = await api.enableUser(userId);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_enable_user")
      );
    }

    toast.success(response.data.message);

    fetchUser();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: chosenUser.id,
      bornDate: chosenUser.employee?.bornDate,
      companyId: chosenUser.companyId,
      contact: chosenUser.employee?.contact,
      description: chosenUser.employee?.description,
      email: chosenUser.email,
      employee_id: chosenUser.employeeId,
      firstDayAtWork: chosenUser.employee?.firstDayAtWork,
      firstName: chosenUser.firstName,
      gender: chosenUser.employee?.gender,
      uniqueId: chosenUser.employee?.uniqueId,
      branchCode: chosenUser.employee?.branchCode,
      lastName: chosenUser.lastName,
      vocativeName: chosenUser.vocativeName,
      relationship: chosenUser.employee?.relationship,
      roles: chosenUser.roles,
      team: chosenUser.employee?.team,
      tier: chosenUser.employee?.tier?.id,
      cities: chosenUser.employee?.cities,
      formalEmail: chosenUser.formalEmail
    },
    validate: editUserFormValidations,
    onSubmit: handleUpdateUser
  });

  const fetchUser = async () => {
    const response = await api.getSingleUser({
      id: routeParams.chosenId,
      companyId: routeParams.company
    });

    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : t("failed_to_get_users")
      );
    }

    setChosenUser(response);
  };

  const fetchCompanies = async () => {
    const response = await api.getCompanyOptions(companiesPage, 100);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_companies")
      );
    }

    setCompanyOptions(response.companies);
    setTotalPages(response.pages);
  };

  /**
   * Refetches paginated companies.
   * Fired from on scroll event from select input.
   * Sets new companies to state, along with page number.
   */
  const refetchCompanies = async () => {
    let currentPage = companiesPage;
    let newPage = (currentPage += 1);

    if (newPage > totalPages) return;

    setCompaniesPage(newPage);

    const response = await api.getCompanyOptions(newPage);
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_companies")
      );
    }

    let newCompanies = [];
    setTimeout(() => {
      newCompanies = [...companyOptions, ...response.companies];
      setTimeout(() => {
        setCompanyOptions(newCompanies);
      }, 100);
    }, 100);
  };

  const fetchRoles = async () => {
    const response = await api.getRolesForDropdown();
    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : t("failed_to_get_roles")
      );
    }

    setRoles(response);
  };

  useEffect(() => {
    fetchUser();
    fetchCompanies();
    fetchRoles();
  }, []);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue
  } = formik;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <AddUserFormStyles>
        <TextAndLine title="User info" />
        <div className="userInfoContainer">
          <IntitialsCircle
            size={126}
            value={`${values.firstName} ${values.lastName}`}
          />
          {chosenUser.isDisabled && (
            <span
              style={{ position: "absolute", bottom: "58px", left: "83px" }}
            >
              <UserDisabledIconSvg width="50" height="50" />
            </span>
          )}
          <div className="inputContainer">
            <div className="bottomInputs">
              <div style={{ margin: "10px 10px 0 0", width: 230 }}>
                <div className="labelAndValidation">
                  <InputLabel margin="0 0 5px 0" label="Roles" />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.roles && touched.roles ? errors.roles : ""}
                  </div>
                </div>
                <MultiselectInput
                  name="roles"
                  onBlur={handleBlur}
                  options={roles}
                  handleChange={option => setFieldValue("roles", option)}
                  selectedValues={values.roles}
                  shouldHaveFullWidth
                  customHeight="27px"
                />
              </div>

              <div style={{ margin: "10px 10px 0 0", width: 230 }}>
                <div className="labelAndValidation">
                  <InputLabel margin="0 0 5px 0" label="Company" />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.companyId && touched.companyId
                      ? errors.companyId
                      : ""}
                  </div>
                </div>
                <SelectInput
                  name="companyId"
                  onBlur={handleBlur}
                  options={companyOptions}
                  selectedValues={companyOptions}
                  value={values.companyId}
                  handleChange={option => setFieldValue("companyId", option.id)}
                  fetchMoreData={refetchCompanies}
                  shouldHaveFullWidth
                  customHeight="27px"
                />
              </div>
            </div>
            <div className="topInputs">
              <div style={{ margin: "0 10px 0 0", width: 230 }}>
                <div className="labelAndValidation">
                  <InputLabel label="First name" margin="0 0 5px 0" />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.firstName && touched.firstName
                      ? errors.firstName
                      : ""}
                  </div>
                </div>
                <Input
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div style={{ width: 230 }}>
                <div className="labelAndValidation">
                  <InputLabel margin="0 0 5px 0" label="Last name" />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.lastName && touched.lastName ? errors.lastName : ""}
                  </div>
                </div>
                <Input
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="middleInputs">
              <div style={{ margin: "0 10px 0 0", width: 230 }}>
                <div className="labelAndValidation">
                  <InputLabel margin="0 0 5px 0" label="Email" />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.email && touched.email ? errors.email : ""}
                  </div>
                </div>
                <Input
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div style={{ width: 230 }}>
                <div className="labelAndValidation">
                  <InputLabel margin="0 0 5px 0" label="Vocative name" />
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.vocativeName && touched.vocativeName
                      ? errors.vocativeName
                      : ""}
                  </div>
                </div>
                <Input
                  name="vocativeName"
                  value={values.vocativeName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="middleInputs">
              <div
                style={{
                  display: "flex",
                  margin: "0 10px 0 0",
                  width: 230,
                  gap: "10px"
                }}
              >
                <CheckboxInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isChecked={values.formalEmail}
                  name="formalEmail"
                  width="18px"
                  height="18px"
                />
                <label className="label">Formal email flag</label>
              </div>
            </div>
            {chosenUser.isDisabled && (
              <div className="userDisabledContainer">
                <span className="userDisabledTitle">
                  This user is currently disabled.
                </span>
                <Button
                  onClick={e => handleUserEnable(e)}
                  margin="8px 0 0 0"
                  padding="10px 25px"
                  outline
                >
                  Enable User
                </Button>
              </div>
            )}
          </div>
          <span
            className="resendVerificationLink"
            onClick={resendVerificationLink}
          >
            Resend verification link
          </span>
        </div>
        {doesRoleExist(values.roles, UserRolesKeysEnum.MEMBER) && (
          <>
            <TextAndLine title="Additional Member fields" />
            <MemberFields
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              companyId={values.companyId}
              isEditUserForm
            />
          </>
        )}
        <div className="buttonsContainer">
          <Button margin="0 30px 0 0" padding="10px 25px" type="submit">
            Update user
          </Button>
          <Button
            onClick={() => history.push("/users")}
            margin="0"
            padding="10px 25px"
            outline
          >
            Back
          </Button>
        </div>
      </AddUserFormStyles>
    </form>
  );
};

EditUserForm.propTypes = {
  routeParams: PropTypes.shape()
};

export default EditUserForm;
