import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
// components
import {
  PhoneNumberInput,
  SelectInput,
  IntitialsCircle,
  InputLabel,
  TextAndLine,
  Input,
  CheckboxInput
} from "../../../../components";
// styles
import BasicInfoSectionStyles from "./BasicInfoSectionStyles";
// dropdown options
import { paymentDeadlineOptions } from "./dropdown.options";
//constants
import { CompanyStatusEnum } from "../../../../constants/companyStatus.constants";
import { useTranslation } from "react-i18next";

const BasicInfoSection = ({
  isFormDisplay = false,
  values,
  errors,
  touched,
  setFieldValue,
  handleChange,
  handleBlur
}) => {
  const { t } = useTranslation();
  const handleNumberInput = (e, previousValue) => {
    let inputValue = e.target.validity.valid
      ? parseInt(e.target.value, 0)
      : previousValue;
    if (isNaN(inputValue)) {
      inputValue = "";
    }

    if (inputValue.toString().length >= 9) {
      e.preventDefault();
    }

    setFieldValue(e.target.name, inputValue);
  };

  const handleIdNumberInput = (e, previousValue) => {
    let inputValue = e.target.validity.valid ? e.target.value : previousValue;
    if (isNaN(inputValue)) {
      inputValue = "";
    }
    setFieldValue(e.target.name, inputValue);
  };

  const handleChangeStatus = () => {
    const inputValue =
      values.status === CompanyStatusEnum.TEST_PHASE
        ? CompanyStatusEnum.LIVE
        : CompanyStatusEnum.TEST_PHASE;
    setFieldValue("status", inputValue);
  };

  const handleToggleVat = () => {
    setFieldValue("vatEnabled", values.vatEnabled ? false : true);
  };

  const handleToggleReimbursement = () => {
    setFieldValue("hasReimbursement", values.hasReimbursement ? false : true);
  };

  const handleToggleTokensToRSD = () => {
    setFieldValue("viewInRsd", values.viewInRsd ? false : true);
  };

  const handleToggleBonusBudget = () => {
    values.bonusBudgetEnabled &&
      toast.warn(t("the_company_is_not_in_bonus_budget_warning"));
    setFieldValue(
      "bonusBudgetEnabled",
      values.bonusBudgetEnabled ? false : true
    );
  };

  const handleToggleOnlySSOLogin = () => {
    values.onlySSOLoginEnabled &&
      toast.warn(t("the_company_is_not_in_sso_mode_warning"));
    setFieldValue(
      "onlySSOLoginEnabled",
      values.onlySSOLoginEnabled ? false : true
    );
  };

  const handleKeyDown = e => {
    if (e.key === "." || e.key === "," || e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  return (
    <BasicInfoSectionStyles>
      <TextAndLine title="Basic info" />
      <div className="basicInfoContainer">
        <IntitialsCircle size={126} value={values.name} isFirstLetter />
        <div className="inputsContainer">
          <div className="topInputs">
            <div style={{ width: 220 }}>
              <div className="labelAndValidation">
                <InputLabel margin="0 0 5px 0" label="Company name" />
                {isFormDisplay && (
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.name && touched.name ? errors.name : ""}
                  </div>
                )}
              </div>
              {isFormDisplay ? (
                <Input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              ) : (
                <p className="infoText">{values.name}</p>
              )}
            </div>
            <div style={{ width: 220, margin: "0 10px" }}>
              <div className="labelAndValidation">
                <InputLabel margin="0 0 5px 0" label="PIB" />
                {isFormDisplay && (
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.pib && touched.pib ? errors.pib : ""}
                  </div>
                )}
              </div>
              {isFormDisplay ? (
                <Input
                  name="pib"
                  pattern="^\d+$"
                  type="number"
                  max={999999999}
                  value={values.pib}
                  onChange={e => handleNumberInput(e, values.pib)}
                  onKeyDown={e => handleKeyDown(e)}
                  onBlur={handleBlur}
                />
              ) : (
                <p className="infoText">{values.pib}</p>
              )}
            </div>
            <div style={{ width: 220 }}>
              <div className="labelAndValidation">
                <InputLabel margin="0 0 5px 0" label="Identification number" />
                {isFormDisplay && (
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.idNumber && touched.idNumber ? errors.idNumber : ""}
                  </div>
                )}
              </div>
              {isFormDisplay ? (
                <Input
                  name="idNumber"
                  type="text"
                  pattern="[0-9]*"
                  value={values.idNumber}
                  onChange={e => handleIdNumberInput(e, values.idNumber)}
                  onBlur={handleBlur}
                />
              ) : (
                <p className="infoText">{values.idNumber}</p>
              )}
            </div>
          </div>
          <div className="middleInputs">
            <div style={{ width: 335, margin: "0 10px 0 0" }}>
              <div className="labelAndValidation">
                <InputLabel margin="0 0 5px 0" label="Invoice email" />
                {isFormDisplay && (
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.invoiceEmail && touched.invoiceEmail
                      ? errors.invoiceEmail
                      : ""}
                  </div>
                )}
              </div>
              {isFormDisplay ? (
                <Input
                  type="email"
                  name="invoiceEmail"
                  value={values.invoiceEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              ) : (
                <p className="infoText">{values.invoiceEmail}</p>
              )}
            </div>
            <div style={{ width: 335 }}>
              <div className="labelAndValidation">
                <InputLabel margin="0 0 5px 0" label="Address" />
                {isFormDisplay && (
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.address && touched.address ? errors.address : ""}
                  </div>
                )}
              </div>
              {isFormDisplay ? (
                <Input
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              ) : (
                <p className="infoText">{values.address}</p>
              )}
            </div>
          </div>
          <div className="bottomInputs">
            <div style={{ width: 220, margin: "0 10px 0 0" }}>
              <div className="labelAndValidation">
                <InputLabel margin="0 0 5px 0" label="Contact" />
                {isFormDisplay && (
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.contact && touched.contact ? errors.contact : ""}
                  </div>
                )}
              </div>
              {isFormDisplay ? (
                <PhoneNumberInput
                  defaultCountry="rs"
                  name="contact"
                  onChange={phone => setFieldValue("contact", phone)}
                  value={values.contact}
                  onBlur={handleBlur}
                />
              ) : (
                <p className="infoText">{values.contact}</p>
              )}
            </div>
            <div style={{ width: 220, margin: "0 10px 0 0" }}>
              <div className="labelAndValidation">
                <InputLabel margin="0 0 5px 0" label="Payment deadline" />
                {isFormDisplay && (
                  <div className="errorMsg" style={{ marginBottom: 5 }}>
                    {errors.paymentDeadline && touched.paymentDeadline
                      ? errors.paymentDeadline
                      : ""}
                  </div>
                )}
              </div>
              {isFormDisplay ? (
                <SelectInput
                  name="paymentDeadline"
                  onBlur={handleBlur}
                  options={paymentDeadlineOptions}
                  selectedValues={paymentDeadlineOptions}
                  value={values.paymentDeadline}
                  handleChange={option =>
                    setFieldValue("paymentDeadline", option.name)
                  }
                  customHeight="27px"
                />
              ) : (
                <p
                  className="infoText"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    overflow: "hidden"
                  }}
                >
                  {values.paymentDeadline}
                </p>
              )}
            </div>
            <div className="testPhaseInput">
              <InputLabel margin="5px 0" label="Enable VAT" />
              {isFormDisplay && (
                <div className="checkboxContainer">
                  <CheckboxInput
                    name="vat_enabled"
                    isChecked={values.vatEnabled}
                    onChange={handleToggleVat}
                    onBlur={handleBlur}
                  />
                  <InputLabel
                    shouldHaveColon={false}
                    label="When you check the box, the company will be able to use VAT"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="testPhaseInput">
            <InputLabel margin="5px 0" label="Only bonus budget" />
            {isFormDisplay && (
              <div className="checkboxContainer">
                <CheckboxInput
                  name="bonus_budget"
                  isChecked={values.bonusBudgetEnabled}
                  onChange={handleToggleBonusBudget}
                  onBlur={handleBlur}
                />
                <InputLabel label="When you check the box, the company have only bonus budget" />
              </div>
            )}
          </div>
          <div className="testPhaseInput">
            <InputLabel margin="5px 0" label="Only SSO login" />
            {isFormDisplay && (
              <div className="checkboxContainer">
                <CheckboxInput
                  name="only_sso_login"
                  isChecked={values.onlySSOLoginEnabled}
                  onChange={handleToggleOnlySSOLogin}
                  onBlur={handleBlur}
                />
                <InputLabel label="When you check the box, the companies HR have only SSO login option" />
              </div>
            )}
          </div>
          <div className="testPhaseInput">
            <InputLabel margin="5px 0" label="Test phase" />
            {isFormDisplay && (
              <div className="checkboxContainer">
                <CheckboxInput
                  name="status"
                  isChecked={values.status === CompanyStatusEnum.TEST_PHASE}
                  onChange={handleChangeStatus}
                  onBlur={handleBlur}
                />
                <InputLabel label="When you check the box, the company is in the test phase" />
              </div>
            )}
          </div>
          <div className="testPhaseInput">
            <InputLabel margin="5px 0" label="Enable reimbursement" />
            {isFormDisplay && (
              <div className="checkboxContainer">
                <CheckboxInput
                  name="hasReimbursement"
                  isChecked={values.hasReimbursement}
                  onChange={handleToggleReimbursement}
                  onBlur={handleBlur}
                />
                <InputLabel
                  shouldHaveColon={false}
                  label="When you check the box, the company will be able to use reimbursement"
                />
              </div>
            )}
          </div>
          <div className="testPhaseInput">
            <InputLabel margin="5px 0" label="Enable RSD" />
            {isFormDisplay && (
              <div className="checkboxContainer">
                <CheckboxInput
                  name="viewInRsd"
                  isChecked={values.viewInRsd}
                  onChange={handleToggleTokensToRSD}
                  onBlur={handleBlur}
                />
                <InputLabel
                  shouldHaveColon={false}
                  label="When you check the box, the company will see the values in RSD"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </BasicInfoSectionStyles>
  );
};

BasicInfoSection.propTypes = {
  isFormDisplay: PropTypes.bool,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func
};

export default BasicInfoSection;
