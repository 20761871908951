import validator from "validator";
import { findLocales } from "../../../utils/helpers/findLocales";

export const validateLogin = ({ email, password }) => {
  const errors = {};
  const isInLanguage = findLocales("rs");

  if (!email) {
    errors.email = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (!validator.isEmail(email)) {
    errors.email = "Invalid email";
  }

  if (!password) {
    errors.password = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (password.length < 8) {
    errors.password = "At least 8 characters";
  }

  return errors;
};
