import { convertEditorStateToString } from "../textEditor.utils";
import { removeSpacesAndHTML } from "../general.utils";
import { findLocales } from "../../utils/helpers/findLocales";

export const validateProviderForm = ({ name, description, photo, summary }) => {
  const errors = {};

  const isInLanguage = findLocales("rs");

  if (!name.trim()) {
    errors.name = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (removeSpacesAndHTML(name).length > 40) {
    errors.name = "Too long";
  } else if (!removeSpacesAndHTML(name)) {
    errors.name = "HTML tag is not allowed";
  }

  const descriptionString = convertEditorStateToString(description);
  const tagRegex = /<.+?>/g;
  const formattedDescription = descriptionString.replace(tagRegex, "").trim();
  if (formattedDescription.length > 1001) {
    errors.description = "Too long";
  }

  if (!summary.trim()) {
    errors.summary = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (removeSpacesAndHTML(summary).length < 20) {
    errors.summary = "Too short";
  } else if (removeSpacesAndHTML(summary).length > 90) {
    errors.summary = "Too long";
  } else if (!removeSpacesAndHTML(summary)) {
    errors.summary = "HTML tag is not allowed";
  }

  if (!photo) {
    errors.photo = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  return errors;
};
