import React, { useEffect, useState } from "react";
import LanguageDropdownStyles from "./LanguageDropdownStyles";
import { useTranslation } from "react-i18next";
import { CountryFlags } from "./Icons";

export const LanguageDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const language = localStorage.getItem("locales");

  const handleDropdownOpen = () => setIsDropdownOpen(state => !state);

  const handleLanguageChange = language => {
    i18n.changeLanguage(language);
    localStorage.setItem("locales", language);
  };

  return (
    <LanguageDropdownStyles>
      <div className="flagDropdownWrapper">
        <button className="shownFlag" onClick={handleDropdownOpen}>
          {CountryFlags(language)}
          <span className="shownLanguage">{t(language)}</span>
        </button>
        {isDropdownOpen && (
          <div className="flagDropdown">
            <button
              className="languageItem"
              onClick={() => handleLanguageChange("rs")}
              disabled={language === "rs"}
            >
              {CountryFlags("rs")}
              <span className="shownLanguage">{t("rs")}</span>
            </button>
            <button
              className="languageItem"
              disabled={language === "en"}
              onClick={() => handleLanguageChange("en")}
            >
              {CountryFlags("en")}
              <span className="shownLanguage">{t("en")}</span>
            </button>
          </div>
        )}
      </div>
    </LanguageDropdownStyles>
  );
};
