import { findLocales } from "../../utils/helpers/findLocales";
import {
  isEmpty,
  isStringAllCaps,
  removeSpacesAndHTML
} from "../general.utils";
import { convertEditorStateToString } from "../textEditor.utils";

export const validateBenefitGroupForm = ({
  name,
  photo,
  description,
  categories,
  taxes,
  cities,
  isRemote,
  summary
}) => {
  const isInLanguage = findLocales("rs");

  const errors = {};

  if (!name.trim()) {
    errors.name = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (removeSpacesAndHTML(name).length > 30) {
    errors.name = "Name too long";
  } else if (!removeSpacesAndHTML(name)) {
    errors.name = "HTML tag is not allowed";
  }

  const descriptionString = convertEditorStateToString(description);
  const tagRegex = /<.+?>/g;
  const formattedDescription = descriptionString.replace(tagRegex, "").trim();
  if (formattedDescription.length > 1001) {
    errors.description = "Too long";
  }

  if (!summary.trim()) {
    errors.summary = isInLanguage ? "*Obavezno polje" : "* Required";
  } else if (removeSpacesAndHTML(summary).length < 20) {
    errors.summary = "Too short";
  } else if (removeSpacesAndHTML(summary).length > 90) {
    errors.summary = "Too long";
  }

  if (isEmpty(categories)) {
    errors.categories = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  if (!isEmpty(taxes) && isStringAllCaps(taxes)) {
    errors.taxes = "All caps not allowed";
  }

  if (!isEmpty(taxes) && removeSpacesAndHTML(taxes).length > 100) {
    errors.taxes = "Too long";
  }

  if (!photo) {
    errors.photo = isInLanguage ? "*Obavezno polje" : "* Required";
  }

  if (!isRemote) {
    if (isEmpty(cities)) {
      errors.cities = isInLanguage ? "*Obavezno polje" : "* Required";
    }
  }

  return errors;
};
