import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
// styles
import { BenefitGroupUsersTableStyles } from "./benefitGroupUsersTable.styles";
// builder
import { tableHead, tableHeaderReducer } from "./builder";
// components
import {
  Card,
  TextAndLine,
  Table,
  TableHead,
  TableRow,
  ListPlaceHolder,
  SearchInput
} from "../../../../components";
import UserBenefitsCount from "./UserBenefitsCount/UserBenefitsCount";
//services
import * as actionCreators from "../../../../actions/hr/benefits.actions";
import * as api from "../../../../services/api/hr/benefits.service";
import { getOrderColumns } from "../../../../services/getOrderColumns";
import { useTranslation } from "react-i18next";

// config
const LIMIT = 20;

const BenefitGroupUsersTable = ({
  benefitGroupId,
  users,
  page,
  count,
  setHrBenefitGroupUsers,
  setHrBenefitGroupUsersPage
}) => {
  const { t } = useTranslation();
  const [tableHeaders, dispatch] = useReducer(tableHeaderReducer, tableHead);
  let search = "";

  const handleFetchAfterSearch = () => {
    getBenefitGroupUsers();
  };

  const handleSearch = searchText => {
    search = searchText;
  };

  const getBenefitGroupUsers = async () => {
    const response = await api.getBenefitGroupUsers({
      benefitGroupId,
      limit: LIMIT,
      page,
      orderColumns: getOrderColumns(tableHeaders),
      searchQuery: search
    });
    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : t("failed_to_get_benefit_group_users")
      );
    }

    setHrBenefitGroupUsers(response.employees, response.count);
  };

  useEffect(() => {
    getBenefitGroupUsers();
  }, [page, tableHeaders]);

  const toggleSortFunc = id => {
    dispatch({ type: id });
  };

  return (
    <BenefitGroupUsersTableStyles>
      <Card margin="60px 0 0 0">
        <div className="header">
          <TextAndLine title={t("active_users")} />
          <SearchInput
            fetchData={handleFetchAfterSearch}
            setSearch={handleSearch}
            search={search}
            customClass="benefitGroupUsersSearch"
            setPagginationPage={setHrBenefitGroupUsersPage}
          />
        </div>
        {users.length ? (
          <>
            <Table>
              <div className="tableHead">
                {tableHeaders.map(tableColumn => (
                  <TableHead
                    id={tableColumn.id}
                    tableHeadColName={t(tableColumn.headName)}
                    key={tableColumn.id}
                    isSortedUp={tableColumn.isSortedUp}
                    justifyContent={tableColumn.justifyContent}
                    colWidth={tableColumn.width}
                    margin={tableColumn.margin}
                    Component={tableColumn.Component}
                    isSortable={tableColumn.isSortable}
                    toggleSortFunc={toggleSortFunc}
                  />
                ))}
              </div>

              <div className="tableRow">
                {users.map(activeUser => (
                  <TableRow
                    key={activeUser.id}
                    columnOne={`${activeUser.firstName} ${activeUser.lastName}`}
                    columnTwo={activeUser.tier.name}
                    tokens={
                      activeUser.remainingTokens >= 0
                        ? activeUser.remainingTokens
                        : 0
                    }
                    columnThree={activeUser.tier.tokens}
                    columnFour={
                      <UserBenefitsCount
                        count={activeUser.activeBenefitsCount}
                      />
                    }
                    additionalLinkParams=""
                    columnFive={activeUser.userBenefits.title}
                    tableStylings={{
                      firstColWidth: "20%",
                      firstColJustifyContent: "flex-start",
                      firstColPadding: "0",
                      firstColMargin: "0",
                      secondColWidth: "15%",
                      secondColJustifyContent: "center",
                      secondColPadding: "0",
                      secondColMargin: "0",
                      thirdColWidth: "20%",
                      thirdColJustifyContent: "center",
                      thirdColPadding: "0",
                      thirdColMargin: "0",
                      forthColWidth: "25%",
                      forthColJustifyContent: "center",
                      forthColPadding: "0",
                      forthColMargin: "0",
                      fifthColWidth: "20%",
                      fifthColJustifyContent: "center",
                      fifthColPadding: "0",
                      fifthColMargin: ""
                    }}
                  />
                ))}
              </div>
            </Table>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10
              }}
            >
              <Pagination
                currentPage={page}
                totalSize={count}
                sizePerPage={LIMIT}
                changeCurrentPage={page => setHrBenefitGroupUsersPage(page)}
                theme="border-bottom"
              />
            </div>
          </>
        ) : (
          <ListPlaceHolder />
        )}
      </Card>
    </BenefitGroupUsersTableStyles>
  );
};

const mapStateToProps = state => {
  return {
    users: state.benefits.editPageState.users,
    page: state.benefits.editPageState.page,
    count: state.benefits.editPageState.count
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setHrBenefitGroupUsers: (users, count) =>
      dispatch(actionCreators.setHrBenefitGroupUsers(users, count)),
    setHrBenefitGroupUsersPage: page =>
      dispatch(actionCreators.setHrBenefitGroupUsersPage(page))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenefitGroupUsersTable);
